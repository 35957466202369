<template>
  <div class="extra-activity">
    <div class="extra-activity__header">
      <Header
        is-flex
        :title="extraActivity.ready ? extraActivity.data.name : ''"
        :navigation="{
          label: $t('commons.extraActivities'),
          route: {
            name: 'home',
          },
        }"
        :loading="extraActivity.loading"
      >
        <template #right>
          <div class="extra-activity__icon">
            <transition name="fade">
              <img
                v-if="extraActivity.ready"
                :src="require(
                  `@/assets/extraActivities/${extraActivity.data.type}-icon.png`
                )"
              >
            </transition>
          </div>
        </template>
        <template #bottom>
          <Tabs
            theme="dark"
            :tabs="tabs"
          />
        </template>
      </Header>
    </div>
    <ExtraActivityContextWrapper
      :selected-property="selectedProperty"
      @resetProperty="selectedProperty = null"
    >
      <template #groupSelection="{ groupSelection }">
        <ExtraActivityGroupPicker
          v-if="groupSelection"
          v-model="selectedProperty"
          :loading="groupSelection.loading"
          :groups="groupSelection.data"
        />
      </template>
      <template #assignmentsList="{ assignmentsList }">
        <template v-if="assignmentsList">
          <AssignmentsListSkeleton
            v-if="assignmentsList.loading"
          />
          <AssignmentsList
            v-else-if="assignmentsList.data"
            :assignments="assignmentsList.data"
          >
            <template #default="{ assignment }">
              <div>
                <s-button
                  v-if="assignment.status === 'finished'"
                  variation="secondary"
                  @click="handlerAssignmentAction(assignment)"
                >
                  {{ $t('commons.seeReport') }}
                </s-button>
                <s-button
                  v-else
                  icon-left="play"
                  @click="handlerAssignmentAction(assignment)"
                >
                  {{
                    assignment.status === 'start'
                      ? $t('commons.start')
                      : $t('commons.resume')
                  }}
                </s-button>
              </div>
            </template>
          </AssignmentsList>
        </template>
      </template>
    </ExtraActivityContextWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Header from '@/components/Header/Header'
import Tabs from '@/components/Tabs'
import ExtraActivityGroupPicker from '@/components/ExtraActivityGroupPicker'
import updateRoute from '@/mixins/updateRoute'
import mediaQueries from '@/mixins/mediaQueries'
import AssignmentsListSkeleton from '@/components/Assignments/AssignmentsListSkeleton'
import AssignmentsList from '@/components/Assignments/AssignmentsList'
import ExtraActivityContextWrapper from '@/components/ExtraActivityContextWrapper'

export default {
  name: 'ExtraActivity',
  components: {
    Header,
    ExtraActivityContextWrapper,
    Tabs,
    ExtraActivityGroupPicker,
    AssignmentsListSkeleton,
    AssignmentsList,
  },
  mixins: [ mediaQueries, updateRoute ],
  data() {
    return {
      selectedProperty: null,
    }
  },
  computed: {
    ...mapGetters([
      'extraActivity',
      'extraActivitiesDifficulties',
      'extraActivityProperties',
    ]),
    extraActivityId() {
      return parseInt(this.$route.params.extraActivityId, 10)
    },
    handleExtraActivityCategory() {
      return this.$route.params.context === 'year' ? this.$track.category.yearlyEnemPractice : this.$track.category.difficultyEnemPractice
    },
    tabs() {
      return [
        {
          name: this.$t('commons.extraActivitiesTabs.byYear'),
          route: {
            name: 'extra-activities-by-context',
            params: {
              context: 'year',
            },
          },
        },
        {
          name: this.$t('commons.extraActivitiesTabs.byDifficulty'),
          route: {
            name: 'extra-activities-by-context',
            params: {
              context: 'difficulty',
            },
          },
        },
      ]
    },
    requiredProperty() {
      return this.$route.query.property
    },
    requiredContext() {
      return this.$route.query.context
    },
    dataSource() {
      if (this.requiredContext) {
        return this.requiredContext === 'year'
          ? this.extraActivityProperties.data
          : this.extraActivitiesDifficulties.data
      }

      return null
    },
  },
  created() {
    this.fetchExtraActivity()
    this.redirectToContext()
  },
  methods: {
    ...mapActions([
      'getExtraActivity',
    ]),
    async fetchExtraActivity() {
      if (!this.extraActivity.ready) {
        this.getExtraActivity(this.extraActivityId)
      }
    },
    notFinishedAssignment(assignment) {
      return assignment.status === 'start' || assignment.status === 'resume'
    },
    handlerAssignmentAction(assignment) {
      if (assignment.status === 'finished') {
        this.$trackEvent({
          category: this.handleExtraActivityCategory,
          action: this.$track.action.finishedChapter,
          label: assignment.title,
        })

        this.$router.push({
          name: 'extra-activity-report',
          params: {
            lectureId: this.lectureId,
            questionnaireCode: assignment.questionnaireCode,
          },
          query: { ...this.$route.query, context: this.$route.params.context },
        })
      } else if (assignment.status !== 'unreleased') {
        this.$router.push({
          name: 'extra-activity-question',
          params: {
            questionnaireCode: assignment.questionnaireCode,
          },
          query: { ...this.$route.query, context: this.$route.params.context },
        })
      }
    },
    redirectToContext() {
      const defaultTab = this.tabs[0]
      const selectedTab = this.tabs.find(
        (tab) => tab.route.params.context === this.requiredContext
      )

      if (this.dataSource && selectedTab) {
        this.selectedProperty = this.dataSource.find(
          (property) => property.id === this.requiredProperty
        )

        this.$router.replace({
          ...selectedTab.route,
          query: { property: this.requiredProperty },
        })
      } else if (this.$route.name !== defaultTab.name) {
        this.$router.replace(defaultTab.route)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.extra-activity {
  &__header {
    ::v-deep .header {
      background-color: #1F3161;

      &__title {
        margin-right: $size-s;
      }
    }
  }
}
</style>
