var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"extra-activity"},[_c('div',{staticClass:"extra-activity__header"},[_c('Header',{attrs:{"is-flex":"","title":_vm.extraActivity.ready ? _vm.extraActivity.data.name : '',"navigation":{
        label: _vm.$t('commons.extraActivities'),
        route: {
          name: 'home',
        },
      },"loading":_vm.extraActivity.loading},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"extra-activity__icon"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.extraActivity.ready)?_c('img',{attrs:{"src":require(
                ("@/assets/extraActivities/" + (_vm.extraActivity.data.type) + "-icon.png")
              )}}):_vm._e()])],1)]},proxy:true},{key:"bottom",fn:function(){return [_c('Tabs',{attrs:{"theme":"dark","tabs":_vm.tabs}})]},proxy:true}])})],1),_c('ExtraActivityContextWrapper',{attrs:{"selected-property":_vm.selectedProperty},on:{"resetProperty":function($event){_vm.selectedProperty = null}},scopedSlots:_vm._u([{key:"groupSelection",fn:function(ref){
              var groupSelection = ref.groupSelection;
return [(groupSelection)?_c('ExtraActivityGroupPicker',{attrs:{"loading":groupSelection.loading,"groups":groupSelection.data},model:{value:(_vm.selectedProperty),callback:function ($$v) {_vm.selectedProperty=$$v},expression:"selectedProperty"}}):_vm._e()]}},{key:"assignmentsList",fn:function(ref){
              var assignmentsList = ref.assignmentsList;
return [(assignmentsList)?[(assignmentsList.loading)?_c('AssignmentsListSkeleton'):(assignmentsList.data)?_c('AssignmentsList',{attrs:{"assignments":assignmentsList.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var assignment = ref.assignment;
return [_c('div',[(assignment.status === 'finished')?_c('s-button',{attrs:{"variation":"secondary"},on:{"click":function($event){return _vm.handlerAssignmentAction(assignment)}}},[_vm._v(" "+_vm._s(_vm.$t('commons.seeReport'))+" ")]):_c('s-button',{attrs:{"icon-left":"play"},on:{"click":function($event){return _vm.handlerAssignmentAction(assignment)}}},[_vm._v(" "+_vm._s(assignment.status === 'start' ? _vm.$t('commons.start') : _vm.$t('commons.resume'))+" ")])],1)]}}],null,true)}):_vm._e()]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }