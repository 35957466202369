var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"adaptiveSelector",staticClass:"adaptive-selector-dropdown"},[_c('button',{ref:"adaptiveSelectorHandle",staticClass:"adaptive-selector-dropdown__handle",attrs:{"disabled":!_vm.selectedOption},on:{"click":_vm.toggleOptions}},[(_vm.selectedOption)?[_vm._t("selectedOption",[_vm._v(" "+_vm._s(_vm.selectedOption)+" ")],{"option":_vm.selectedOption}),_c('icon',{staticClass:"adaptive-selector-dropdown__handle__caret",attrs:{"type":"chevron-down"}})]:[_vm._t("skeleton",[_c('skeleton-loader',{attrs:{"width":"200px","height":"19px"}})])]],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.open)?_c('div',{staticClass:"adaptive-selector-dropdown__overlay",on:{"click":_vm.hideOptions}}):_vm._e()]),_c('transition',{attrs:{"name":_vm.mq_l ? 'slide-bottom' : 'dropdown'}},[(_vm.open)?_c('div',{ref:"adaptiveSelectorOptions",class:[
        'adaptive-selector-dropdown__options',
        ("--" + _vm.dropdownDirection) ],style:({
        height: _vm.optionsHeight ? (_vm.optionsHeight + "px") : 'auto'
      })},[_c('div',{staticClass:"adaptive-selector-dropdown__options__header"},[_vm._t("optionsTitle",[_c('h4',{staticClass:"adaptive-selector-dropdown__options__header__title"},[_vm._v(" "+_vm._s(_vm.optionsTitle)+" ")])]),_c('s-button',{attrs:{"icon":"x","variation":"tertiary"},on:{"click":_vm.hideOptions}})],2),_c('div',{staticClass:"adaptive-selector-dropdown__options__body"},[(_vm.filterable)?_c('div',{staticClass:"adaptive-selector-dropdown__options__search"},[_c('s-input',{attrs:{"icon-left":"search","width":"100%","placeholder":_vm.filterPlaceholder},on:{"input":function($event){return _vm.$emit('search', $event)}}})],1):_vm._e(),(_vm.groupBy)?_vm._l((_vm.groupedOptions),function(ref,groupIndex){
      var group = ref[0];
      var optionsInGroup = ref[1];
return _c('div',{key:groupIndex,staticClass:"adaptive-selector-dropdown__option-group"},[_c('div',{staticClass:"adaptive-selector-dropdown__option-group__title"},[_vm._v(" "+_vm._s(group)+" ")]),_vm._l((optionsInGroup),function(option,optionIndex){return _c('div',{key:optionIndex,class:_vm.optionClass(option),attrs:{"tabindex":"0","aria-role":"button"},on:{"click":function($event){return _vm.selectOption(option)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.selectOption(option)}}},[_c('div',[_vm._t("option",[_vm._v(" "+_vm._s(option)+" ")],{"option":option})],2),(_vm.isEqual(option, _vm.selectedOption))?_c('icon',{attrs:{"type":"check","stroke-width":"3"}}):_vm._e()],1)})],2)}):_vm._l((_vm.options),function(option,index){return _c('div',{key:index,class:_vm.optionClass(option),attrs:{"tabindex":0},on:{"click":function($event){return _vm.selectOption(option)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.selectOption(option)}}},[_c('div',[_vm._t("option",[_vm._v(" "+_vm._s(option)+" ")],{"option":option})],2),(_vm.isEqual(option, _vm.selectedOption))?_c('icon',{attrs:{"type":"check","stroke-width":"3"}}):_vm._e()],1)}),(_vm.options.length === 0)?_c('div',{staticClass:"adaptive-selector-dropdown__options__empty"},[(_vm.emptyImage)?_c('img',{attrs:{"src":_vm.emptyImage}}):_vm._e(),_c('p',{staticClass:"adaptive-selector-dropdown__options__empty__text"},[_vm._v(" "+_vm._s(_vm.emptyString || _vm.$t('adaptiveSelector.emptyString'))+" ")])]):_vm._e()],2)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }