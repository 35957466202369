<template>
  <div
    ref="adaptiveSelector"
    class="adaptive-selector-dropdown"
  >
    <button
      ref="adaptiveSelectorHandle"
      class="adaptive-selector-dropdown__handle"
      :disabled="!selectedOption"
      @click="toggleOptions"
    >
      <template v-if="selectedOption">
        <slot
          name="selectedOption"
          :option="selectedOption"
        >
          {{ selectedOption }}
        </slot>
        <icon
          class="adaptive-selector-dropdown__handle__caret"
          type="chevron-down"
        />
      </template>
      <template v-else>
        <slot name="skeleton">
          <skeleton-loader
            width="200px"
            height="19px"
          />
        </slot>
      </template>
    </button>
    <transition name="fade">
      <div
        v-if="open"
        class="adaptive-selector-dropdown__overlay"
        @click="hideOptions"
      />
    </transition>
    <transition :name="mq_l ? 'slide-bottom' : 'dropdown'">
      <div
        v-if="open"
        ref="adaptiveSelectorOptions"
        :class="[
          'adaptive-selector-dropdown__options',
          `--${dropdownDirection}`,
        ]"
        :style="{
          height: optionsHeight ? `${optionsHeight}px` : 'auto'
        }"
      >
        <div class="adaptive-selector-dropdown__options__header">
          <slot name="optionsTitle">
            <h4 class="adaptive-selector-dropdown__options__header__title">
              {{ optionsTitle }}
            </h4>
          </slot>
          <s-button
            icon="x"
            variation="tertiary"
            @click="hideOptions"
          />
        </div>
        <div class="adaptive-selector-dropdown__options__body">
          <div
            v-if="filterable"
            class="adaptive-selector-dropdown__options__search"
          >
            <s-input
              icon-left="search"
              width="100%"
              :placeholder="filterPlaceholder"
              @input="$emit('search', $event)"
            />
          </div>
          <template v-if="groupBy">
            <div
              v-for="([ group, optionsInGroup ], groupIndex)
                in groupedOptions"
              :key="groupIndex"
              class="adaptive-selector-dropdown__option-group"
            >
              <div class="adaptive-selector-dropdown__option-group__title">
                {{ group }}
              </div>
              <div
                v-for="(option, optionIndex) in optionsInGroup"
                :key="optionIndex"
                tabindex="0"
                aria-role="button"
                :class="optionClass(option)"
                @click="selectOption(option)"
                @keyup.enter="selectOption(option)"
              >
                <div>
                  <slot
                    name="option"
                    :option="option"
                  >
                    {{ option }}
                  </slot>
                </div>
                <icon
                  v-if="isEqual(option, selectedOption)"
                  type="check"
                  stroke-width="3"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(option, index) in options"
              :key="index"
              :tabindex="0"
              :class="optionClass(option)"
              @click="selectOption(option)"
              @keyup.enter="selectOption(option)"
            >
              <div>
                <slot
                  name="option"
                  :option="option"
                >
                  {{ option }}
                </slot>
              </div>
              <icon
                v-if="isEqual(option, selectedOption)"
                type="check"
                stroke-width="3"
              />
            </div>
          </template>
          <div
            v-if="options.length === 0"
            class="adaptive-selector-dropdown__options__empty"
          >
            <img
              v-if="emptyImage"
              :src="emptyImage"
            >
            <p class="adaptive-selector-dropdown__options__empty__text">
              {{ emptyString || $t('adaptiveSelector.emptyString') }}
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import mediaQueries from '@/mixins/mediaQueries'

export default {
  name: 'AdaptiveSelectorDropdown',
  mixins: [ mediaQueries ],
  model: {
    prop: 'selectedOption',
    event: 'select',
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: [ Object, String ],
      default: null,
    },
    optionsTitle: {
      type: String,
      default: null,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    filterPlaceholder: {
      type: String,
      default: null,
    },
    emptyString: {
      type: String,
      default: null,
    },
    emptyImage: {
      type: String,
      default: null,
    },
    groupBy: {
      type: String,
      default: null,
    },
    dropdownDirection: {
      type: String,
      default: 'right',
      validator: (value) => value.match(/(left|right)/),
    },
  },
  data() {
    return {
      open: false,
      optionsHeight: undefined,
    }
  },
  computed: {
    groupedOptions() {
      if (this.groupBy) {
        return Object.entries(this.options.reduce(
          (previous, current) => {
            const next = { ...previous }
            const group = current[this.groupBy]
            if (!Object.prototype.hasOwnProperty.call(next, group)) {
              next[group] = []
            }
            next[group].push({ ...current })

            return next
          },
          {},
        ))
      }

      return this.options
    },
  },
  methods: {
    isEqual(value, other) {
      return isEqual(value, other)
    },
    optionClass(option) {
      return [
        'adaptive-selector-dropdown__option',
        {
          '--active': isEqual(option, this.selectedOption),
          '--disabled': option && option.disabled,
        },
      ]
    },
    selectOption(option) {
      if (!option.disabled) {
        this.$emit('select', option)
        this.hideOptions()
        this.$refs.adaptiveSelectorHandle.focus()
      }
    },
    toggleOptions() {
      if (this.open) {
        this.hideOptions()
      } else {
        this.showOptions()
      }
    },
    showOptions() {
      this.open = true

      if (this.mq_l) {
        this.$nextTick(() => {
          const { height } = this.$refs.adaptiveSelectorOptions
            .getBoundingClientRect()
          this.optionsHeight = height
        })
      }
      document.body.addEventListener('click', this.clickOutside)
      document.body.addEventListener('keyup', this.keyUpHandler)
      this.$emit('open')
    },
    hideOptions() {
      document.body.removeEventListener('click', this.clickOutside)
      document.body.removeEventListener('keyup', this.keyUpHandler)
      this.open = false
      this.$emit('close')
    },
    clickOutside(event) {
      if (!this.$refs.adaptiveSelector.contains(event.target)) {
        this.hideOptions()
      }
    },
    keyUpHandler(event) {
      if (event.defaultPrevented) {
        return
      }

      if (event.key === 'Esc' || event.key === 'Escape') {
        this.hideOptions()
      }
    },
  },
}
</script>

<style lang="sass" scoped>
$adaptive-selector-shadow: $shadow-s rgba($color-ink, .25)

.adaptive-selector-dropdown
  position: relative

  +mq-l--mf
    width: max-content

  &__name
    flex-grow: 1

  &__handle
    background-color: $color-white
    border: 1px solid #C9CCCF
    box-shadow: $adaptive-selector-shadow
    border-radius: 10px
    font-size: $font-size-heading-6-small
    font-weight: $font-weight-semi-bold
    padding: #{$size-m / 2} $size-s
    position: relative
    text-align: left
    width: 100%
    +flex-center-start
    +transition($speed-fast)

    +mq-m--mf
      width: max-content
      z-index: 2

    &:hover:not([disabled])
      background-color: $color-ice
      cursor: pointer

    &:focus
      border-color: $color-primary-light
      box-shadow: $adaptive-selector-shadow, inset 0 0 0 2px $color-primary-light
      outline: none

    &__caret
      color: $color-ink-light
      flex-shrink: 1
      margin-left: $size-s
      height: $size-m
      width: $size-m

      +mq-l--mf
        margin-left: $size-l

      ::v-deep .feather__content
        height: 100%
        width: 100%

  &__overlay
    +cover
    background-color: transparentize($color-ink, 0.5)
    z-index: 11

    +mq-l--mf
      z-index: 2
      display: none

  &__options
    background-color: #fff
    border-radius: $size-s $size-s 0 0
    bottom: 0
    max-height: 100vh
    left: 0
    position: fixed
    right: 0
    z-index: 12

    +mq-l--mf
      border-radius: $size-s
      bottom: auto
      box-shadow: 0px 5px 14px rgba(52, 60, 88, 0.4)
      max-height: 40vh
      position: absolute
      overflow: auto
      top: 100%
      z-index: 3
      width: max-content

      &.--left
        left: auto

      &.--right
        right: auto

    &__header
      padding: $size-xs $size-xs $size-xs $size-s
      +flex-center-start

      +mq-l--mf
        display: none

      &__title
        flex-grow: 1

    &__body
      box-shadow: inset 0px 5px 5px -5px rgba(0, 0, 0, 0.25)
      max-height: calc(100vh - 45px)
      overflow: auto
      padding: $size-s 0 20vh

      +mq-l--mf
        box-shadow: none
        padding-bottom: $size-s
        overflow: initial

    &__search
      padding: $size-s
      margin-top: -$size-s

      ::v-deep .sas-input__field
        background-color: $color-white

    &__empty
      height: calc(100% - 76px)
      width: 100%
      padding: $size-s
      +flex-column-center

      &__text
        margin-top: $size-s
        text-align: center
        max-width: 240px

        +mq-l--mf
          max-width: 220px

  &__option
    cursor: pointer
    padding: #{$size-m / 2} #{$size-l + $size-m} #{$size-m / 2} $size-s
    position: relative
    +flex-center-start
    +transition($speed-x-fast)

    &:hover,
    &.--active
      background-color: rgba(192, 217, 246, 0.2)

    &:focus
      outline: none
      box-shadow: inset 0 0 0 3px $color-primary-light

    &.--disabled
      cursor: not-allowed
      opacity: 0.5

      &:hover
        background-color: transparent

      &:focus
        box-shadow: none

    .feather
      color: $color-primary
      position: absolute
      right: $size-s

  &__option-group
    border-bottom: 1px solid transparentize($color-ink-lightest, 0.5)
    padding-bottom: $size-s
    margin-bottom: $size-s

    &:last-of-type
      border-bottom: none

      +mq-l--mf
        margin-bottom: 0

    &__title
      font-size: $font-size-heading-3-small
      font-weight: $font-weight-semi-bold
      padding: $size-xs $size-s

      +mq-m--mf
        font-size: $font-size-heading-4

+v-transition(slide-bottom)
  transform: translateY(100%)

+v-transition(dropdown)
  opacity: 0
  transform: translateY(-10%)
</style>
